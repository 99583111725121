import {useState} from 'react';
import ProductCounter from '../../../Catalog/Product/ProductItem/ProductCounter/ProductCounter';
import {ReactComponent as Garbage} from '../../../../assets/svg/basket/garbageClosed.svg';
import {ReactComponent as GarbageActive} from '../../../../assets/svg/basket/garbageOpen2.svg';

import style from './TableBody.module.scss';

const TableBody = (props) => {
  const [quantityChange, setQuantityChange] = useState(
    getQuantityChangeData(props.data),
  );
  const [garbageHover, setGarbageHover] = useState(
    getGarbageHoverData(props.data),
  );

  function getQuantityChangeData(products) {
    let quantityObj = {};

    products.forEach((product) => {
      quantityObj[product.id] = product.quantity;
    });

    return quantityObj;
  }

  function getGarbageHoverData(products) {
    let hoverObj = {};

    products.forEach((product) => {
      hoverObj[product.id] = false;
    });

    return hoverObj;
  }

  const handleUpClick = (value, e, id) => {
    setQuantityChange((prevQuantity) => ({...prevQuantity, [id]: value}));
    props.changeQuantity(id, value);
  };

  const handleDownClick = (value, e, id) => {
    setQuantityChange((prevQuantity) => ({...prevQuantity, [id]: value}));
    props.changeQuantity(id, value);
  };

  const handleChangeQuantity = (value, e, id) => {
    setQuantityChange((prevQuantity) => ({...prevQuantity, [id]: value}));
    props.changeQuantity(id, value);
  };

  const handleBlurProductQuantityInput = (id) => {
    setQuantityChange((prevQuantity) => ({...prevQuantity, [id]: 1}));
    props.changeQuantity(id, 1);
  };

  const priceIdentifier = (product, exchangeRate) => {
    if (product.quantity <= product.minQuantity - 1) {
      return {
        priceUAH: product.priceUAH.toFixed(2),
        priceUSD: (product.priceUAH / exchangeRate).toFixed(2),
      };
    }

    if (
      product.quantity <= product.minQuantity1 - 1 &&
      product.quantity >= product.minQuantity
    ) {
      return {
        priceUAH: (product.priceUSD * exchangeRate).toFixed(2),
        priceUSD: product.priceUSD.toFixed(2),
      };
    }

    if (product.quantity >= product.minQuantity1) {
      return {
        priceUAH: (product.priceUSDless * exchangeRate).toFixed(2),
        priceUSD: product.priceUSDless.toFixed(2),
      };
    }
  };
  return props.data?.map((item, i) => (
    <tr key={i + 1}>
      <td className={style.cart__table_number}>{i + 1}</td>
      <td className={style.cart__table_picture}>
        <div
          className={style.cart__table_picture_item}
          style={{backgroundImage: `url(${item.pictures[0]?.url})`}}></div>
      </td>
      <td className={style.cart__table_title}>
        <div className={style.cart__table_title_row1}>
          <h2>{item.name}</h2>
        </div>
        <div className={style.cart__table_title_row2}>
          <p className={style.product_sku}>
            Art: <span>{item.sku}</span>
          </p>
        </div>
      </td>
      <td className={style.cart__table_price}>
        <div>
          <p className={style.nationalPrice}>
            {priceIdentifier(item, props.exchangeRate).priceUAH} &#8372;
          </p>
        </div>
        <div>
          <p className={style.internationalPrice}>
            {priceIdentifier(item, props.exchangeRate).priceUSD} &#65284;
          </p>
        </div>
      </td>
      <td className={style.cart__table_quantity}>
        {/* <div>
          <p className={style.minQuantity}>
            Введіть кратно: <br /> {item.minQuantity}
          </p>
        </div> */}
        <div className={style.productCounterWrapper}>
          <div className={style.productCounterContainer}>
            <ProductCounter
              productQuantity={quantityChange[item.id]}
              minusClick={handleDownClick}
              plusClick={handleUpClick}
              editInput={handleChangeQuantity}
              blurInput={handleBlurProductQuantityInput}
              id={item.id}
            />
          </div>
        </div>
      </td>
      <td className={style.cart__table_summaryPrice}>
        <div>
          <p className={style.nationalSummary}>
            {(
              item.quantity * priceIdentifier(item, props.exchangeRate).priceUAH
            ).toFixed(2)}{' '}
            &#8372;
          </p>
        </div>
        <div>
          <p className={style.internationSummary}>
            {(
              item.quantity * priceIdentifier(item, props.exchangeRate).priceUSD
            ).toFixed(2)}{' '}
            &#65284;
          </p>
        </div>
      </td>
      <td className={style.cart__table_delete}>
        <span
          id={item.id}
          onClick={() => {
            props.delete(item.id);
          }}
          onMouseOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setGarbageHover({...garbageHover, [item.id]: true});
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setGarbageHover({...garbageHover, [item.id]: false});
          }}
          className={garbageHover[item.id] ? style.icon_active : style.icon}>
          <span className={style.top_block}></span>
          {garbageHover[item.id] ? <GarbageActive /> : <Garbage />}
          <span
            className={
              garbageHover[item.id]
                ? style.bottom_block_active
                : style.bottom_block
            }></span>
        </span>
      </td>
    </tr>
  ));
};

export default TableBody;
