import s from './Quontity.module.scss';

const Quontity = ({quontity, text, isActive, styles}) => {
  return (
    <p>
      <span
        className={s.chooseQuantity}
        style={isActive ? {} : styles.chooseQuontity}>
        {quontity}
        <span style={isActive ? {} : styles.chooseQuontity}>{text}</span>
      </span>
    </p>
  );
};

export default Quontity;
