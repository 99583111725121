
const TableHeadMiddle = () => {
  return (
    <>
    <tr>
      <th>№</th>
      <th>зображення</th>
      <th colSpan='2'>назва/ артикул</th>
      <th>видалити</th>
    </tr>
    </>
  );
}

export default TableHeadMiddle;
