import s from './Price.module.scss';

const Price = ({priceUah, priceUsd, textStyles, first, last}) => {
  return (
    <div
      className={`${s.productItem_price} ${
        first ? s.first : last ? s.last : ''
      }`}>
      <p>
        <span style={textStyles.price}>
          {priceUah} <span style={textStyles.priceSpan}>&#8372;/од</span>
        </span>
      </p>
      <p>
        <span className={s.quontityText} style={textStyles.quontity}>
          {priceUsd} &#65284;/{'од'}
        </span>
      </p>
    </div>
  );
};

export default Price;
