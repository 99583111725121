
const TableHeadMiddle = () => {
  return (
    <>
    <tr>
      <th>позицій</th>
      <th>зображення</th>
      <th colSpan='2'>назва замовлення</th>
      <th>деталі</th>
    </tr>
    </>
  );
}

export default TableHeadMiddle;

