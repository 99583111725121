
const TableHeadMiddle = () => {
  return (
    <>
    <tr>
      <th>дата</th>
      <th>ім'я</th>
      <th colSpan='2'>товар</th>
      <th>дія</th>
    </tr>
    </>
  );
}

export default TableHeadMiddle;
