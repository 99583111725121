import React, {useState, useEffect, useRef} from 'react';
import {ReactComponent as Plus} from '../../../../../assets/plus-button.svg';
import {ReactComponent as Minus} from '../../../../../assets/minus-button.svg';

import s from './ProductCounter.module.scss';

export default function ProductCounter({
  productQuantity,
  minusClick,
  plusClick,
  editInput,
  blurInput,
  id,
}) {
  const [quantityChange, setQuantityChange] = useState(productQuantity);

  const inputRef = useRef(null);

  useEffect(() => {
    if (
      quantityChange !== 0 &&
      (productQuantity > quantityChange || productQuantity < quantityChange)
    ) {
      setQuantityChange(productQuantity);
    }
  }, [productQuantity, quantityChange]);

  const minusClickHandler = (e, id) => {
    if (quantityChange > 1) {
      setQuantityChange((prevQuantity) => {
        minusClick(Number(prevQuantity) - 1, e, id);
        return Number(prevQuantity) - 1;
      });
    }
  };

  const plusClickHandler = (e, id) => {
    if (quantityChange >= 1) {
      setQuantityChange((prevQuantity) => {
        plusClick(Number(prevQuantity) + 1, e, id);
        return Number(prevQuantity) + 1;
      });
    }
  };

  const editProductQuantityHandler = (e, id) => {
    setQuantityChange(Number(e.target.value));
    editInput(Number(e.target.value), e, id);
  };

  const blurProductQuantityInputHandler = (id) => {
    if (quantityChange === '' || quantityChange < 1) {
      setQuantityChange(1);
      blurInput(id);
    }
  };
  return (
    <div className={s.productItem_quantity}>
      <div className={s.minus} onClick={(e) => minusClickHandler(e, id)}>
        <Minus />
      </div>
      <input
        type="text"
        value={quantityChange}
        ref={inputRef}
        className={s.productItem_quantity_input}
        onClick={(e) => e.stopPropagation()}
        onFocus={() => inputRef.current.select()}
        onChange={(e) => editProductQuantityHandler(e, id)}
        onBlur={() => blurProductQuantityInputHandler(id)}
      />
      <div className={s.plus} onClick={(e) => plusClickHandler(e, id)}>
        <Plus />
      </div>
    </div>
  );
}
