import s from './Line.module.scss';

const Line = ({first}) => {
  return (
    <>
      <div className={first ? `${s.lineDevider} ${s.first}` : s.lineDevider}>
        <div className={s.line}></div>
      </div>
    </>
  );
};

export default Line;
