import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  setOneProduct,
  selectAllProducts,
} from '../../../../redux/features/productsSlice';
import ProductCounter from '../../../Catalog/Product/ProductItem/ProductCounter/ProductCounter';
import {ReactComponent as Garbage} from '../../../../assets/svg/basket/garbageClosed.svg';
import {ReactComponent as GarbageActive} from '../../../../assets/svg/basket/garbageOpen2.svg';

import style from './ProductItem.module.scss';

const ProductItem = ({
  id,
  imgUrl,
  title,
  priceUAH,
  priseUSD,
  priseUSDless,
  quantity,
  minQuantity,
  minQuantity1,
  removeFromBasket,
  changeAmount,
}) => {
  const exchangeRate = localStorage.getItem('exchangeRate');
  const [amountChange, setAmountChange] = useState(quantity);
  const [priceChange, setPriceChange] = useState({
    uah:
      amountChange === minQuantity || amountChange < minQuantity1 - 1
        ? priceUAH.toFixed(2)
        : quantity === minQuantity1
        ? (priseUSD * exchangeRate).toFixed(2)
        : quantity > minQuantity1
        ? (priseUSDless * exchangeRate).toFixed(2)
        : minQuantity,
    usd:
      amountChange === minQuantity || amountChange < minQuantity1 - 1
        ? (priceUAH / exchangeRate).toFixed(2)
        : quantity === minQuantity1
        ? priseUSD.toFixed(2)
        : quantity > minQuantity1
        ? priseUSDless.toFixed(2)
        : minQuantity,
  });
  const [garbageHover, setGarbageHover] = useState(false);

  const products = useSelector(selectAllProducts);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (amountChange <= minQuantity - 1) {
      setPriceChange({
        uah: priceUAH.toFixed(2),
        usd: (priceUAH / exchangeRate).toFixed(2),
      });
    }

    if (amountChange <= minQuantity1 - 1 && amountChange >= minQuantity) {
      setPriceChange({
        uah: (priseUSD * exchangeRate).toFixed(2),
        usd: priseUSD.toFixed(2),
      });
    }

    if (amountChange >= minQuantity1) {
      setPriceChange({
        uah: (priseUSDless * exchangeRate).toFixed(2),
        usd: priseUSDless.toFixed(2),
      });
    }
  }, [
    amountChange,
    exchangeRate,
    minQuantity,
    minQuantity1,
    priceUAH,
    priseUSD,
    priseUSDless,
  ]);

  const handleRedirect = (id) => {
    const foundProduct = products.find((product) => product.id === id);
    dispatch(setOneProduct(foundProduct ? foundProduct : {}));
    navigate(`/${id}`);
  };

  const handleClick = () => {
    removeFromBasket(id);
  };

  function trimString(str) {
    if (str.length > 20) {
      return str.slice(0, 20) + '...';
    } else {
      return str;
    }
  }

  const handleMinusClick = (value, e) => {
    e.stopPropagation();

    setAmountChange(value);
    changeAmount(id, value);

    const localStorageProducts = JSON.parse(localStorage.getItem('basket'));
    localStorageProducts.forEach((product, i) => {
      if (localStorageProducts[i].id === id) {
        localStorageProducts[i].quantity = value;
      }
    });

    localStorage.removeItem('basket');
    localStorage.setItem('basket', JSON.stringify(localStorageProducts));
  };

  const handlePlusClick = (value, e) => {
    e.stopPropagation();

    setAmountChange(value);
    changeAmount(id, value);

    const localStorageProducts = JSON.parse(localStorage.getItem('basket'));
    localStorageProducts.forEach((product, i) => {
      if (localStorageProducts[i].id === id) {
        localStorageProducts[i].quantity = value;
      }
    });

    localStorage.removeItem('basket');
    localStorage.setItem('basket', JSON.stringify(localStorageProducts));
  };

  const handleEditProductQuantity = (value) => {
    setAmountChange(value);
    changeAmount(id, value);

    const localStorageProducts = JSON.parse(localStorage.getItem('basket'));
    localStorageProducts.forEach((product, i) => {
      if (localStorageProducts[i].id === id) {
        localStorageProducts[i].quantity = value;
      }
    });

    localStorage.removeItem('basket');
    localStorage.setItem('basket', JSON.stringify(localStorageProducts));
  };

  const handleBlurProductQuantityInput = () => {
    setAmountChange(1);
    changeAmount(id, 1);

    const localStorageProducts = JSON.parse(localStorage.getItem('basket'));
    localStorageProducts.forEach((product, i) => {
      if (localStorageProducts[i].id === id) {
        localStorageProducts[i].quantity = 1;
      }
    });

    localStorage.removeItem('basket');
    localStorage.setItem('basket', JSON.stringify(localStorageProducts));
  };

  return (
    <li className={style.item}>
      <img className={style.image} src={imgUrl} alt="img" />
      <div className={style.wrapper} onClick={() => handleRedirect(id)}>
        <div className={style.wrapperFirst}>
          <p className={style.title}>{trimString(title)}</p>
          <div className={style.counterLine}></div>
          <div className={style.productCounterContainer}>
            <ProductCounter
              productQuantity={amountChange}
              minusClick={handleMinusClick}
              plusClick={handlePlusClick}
              editInput={handleEditProductQuantity}
              blurInput={handleBlurProductQuantityInput}
            />
          </div>
        </div>
        <div style={{textAlign: 'end'}}>
          <p className={style.price}>{priceChange.uah} UAH</p>
          <div className={style.lineContainer}>
            <div className={style.priceLine}></div>
          </div>
          <p className={style.price}>{priceChange.usd} $</p>
        </div>
      </div>
      <span
        id={id}
        className={garbageHover ? style.garbage_active : style.garbage}
        onMouseOver={() => setGarbageHover(true)}
        onMouseLeave={() => setGarbageHover(false)}
        onClick={handleClick}>
        <span className={style.top_block}></span>
        {garbageHover ? <GarbageActive /> : <Garbage />}
        <span
          className={
            garbageHover ? style.bottom_block_active : style.bottom_block
          }></span>
      </span>
    </li>
  );
};

export default ProductItem;
