import {NavLink} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {addProductsToCart} from '../../../../redux/features/cartSlice';

import s from './FavoriteCard.module.scss';

const FavoriteCard = ({card, handelClick}) => {
  const dispatch = useDispatch();

  const handleAddToCart = async () => {
    dispatch(addProductsToCart({...card, quantity: card.quantity + 1}));
  };

  return (
    <div
      className={
        card.styleCard ? `${s[`${card.styleCard}`]} ${s.card}` : s.card
      }>
      <NavLink to={`/${card.id}`} className={s.card_link}>
        <span
          id={card.id}
          className={s.heart}
          onClick={() => handelClick(card.id)}></span>
        <span className={s.clickArea} id={card.id} onClick={(e) => {}}></span>
        <div
          className={s.wrapperImg}
          style={{backgroundImage: `url(${card.pictures[0].url})`}}></div>
        <h3 className={s.title}>{card.name}</h3>
        <div className={s.wrapperText}>
          {card.stock ? (
            <p className={`${s.inStock} ${s.inStockYes}`}>В наявності</p>
          ) : (
            <p className={`${s.inStock} ${s.inStockNo}`}>Немає в наявності</p>
          )}
          <div className={s.wrapperPrice}>
            <p className={s.priceUSD}>{card.priceUSD} $</p>
            <p className={s.priceUAH}>{card.priceUAH} ₴</p>
          </div>
        </div>
      </NavLink>
      <NavLink onClick={handleAddToCart} className={s.button}>
        Додати до кошика
      </NavLink>
    </div>
  );
};

export default FavoriteCard;
